<div class="bg-slate-50 p-4 shadow rounded-md w-full">
    <div class="animate-pulse flex space-x-4">
        <div class="flex-1 space-y-6 py-1">
            <div class="h-5 bg-slate-200 rounded"></div>
            <div class="space-y-3">
                <div class="grid grid-cols-3 gap-4">
                    <div class="h-4 bg-slate-200 rounded col-span-2"></div>
                    <div class="h-4 bg-slate-200 rounded col-span-2"></div>
                    <div class="h-4 bg-slate-200 rounded col-span-2"></div>
                    <div class="h-4 bg-slate-200 rounded col-span-2"></div>
                </div>
                <div class="grid grid-cols-3 gap-4">
                    <div class="h-4 bg-slate-200 rounded col-span-2"></div>
                    <div class="h-4 bg-slate-200 rounded col-span-2"></div>
                    <div class="h-4 bg-slate-200 rounded col-span-2"></div>
                    <div class="h-4 bg-slate-200 rounded col-span-2"></div>
                </div>
            </div>
        </div>
    </div>
</div>
