import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { QueryService } from '@core/services/query.service';


@NgModule({
    declarations: [],
    exports: [],
    imports: [
        HttpClientModule
    ],
    providers: [
        QueryService
    ],
})
export class CoreModule { }
