<header>
    <div class="flex flex-row items-center justify-between m-4">
        @if (metrics) {
        <select [(ngModel)]="countryId" class=" text-white shadow ring-brand-default bg-brand-default border-none rounded-lg focus:outline-none
            p-2" (change)="changeCountryAsync()">
            <option selected [ngValue]="null">All</option>
            @for (country of metrics.countries; track country.id) {
            <option [value]="country.id">{{ country.name }}</option>
            }
        </select>
        }
        @else {
        <div class="bg-slate-200 py-4 px-12 shadow rounded-md animate-pulse">
        </div>
        }
        <a routerLink="/">
            <img ngSrc="/assets/ddfinance-logo.png" alt="DDFinance" width="220" height="50" priority />
        </a>
    </div>
</header>

<main>
    @if (metrics) {
    <div class="grid grid-cols-2 gap-4 p-4">
        <div class="card">
            <h3 class="title">Metrics</h3>

            <div>
                <dl>
                    <dt>Insurance contracts by DDFinance</dt>
                    <dd>{{metrics.numerical.insuranceContracts}}</dd>

                    <dt>Average Household Size</dt>
                    <dd>{{metrics.numerical.avgHouseholdSize | number:'1.2-2'}}</dd>

                    <dt>Claims Settled</dt>
                    <dd>{{metrics.numerical.claimsSettled}}</dd>

                    <dt>Active Trainers</dt>
                    <dd>{{metrics.numerical.trainers}}</dd>

                    <dt>Female customers gender distribution</dt>
                    <dd>{{ metrics.numerical.femaleGenderPercentage | number:'1.2-2' }}%</dd>

                    <dt>Male customers gender distribution</dt>
                    <dd>{{ metrics.numerical.maleGenderPercentage | number:'1.2-2' }}%</dd>

                    @if (countryCurrency) {
                    <dt>Average Customer Premium</dt>
                    <dd>{{ metrics.financial.avgCustomerPremium | currency: countryCurrency : 'symbol' :'1.2-2' }}</dd>

                    <dt>Average Household Premium</dt>
                    <dd>{{ metrics.financial.avgHouseholdPremium | currency: countryCurrency : 'symbol' :'1.2-2' }}</dd>

                    <dt>Average Collective Premium</dt>
                    <dd>{{ metrics.financial.avgCollectivePremium | currency: countryCurrency : 'symbol' :'1.2-2' }}
                    </dd>


                    <dt>Sum Insured</dt>
                    <dd>{{ metrics.financial.sum_insured | currency: countryCurrency : 'symbol' :'1.2-2' }}</dd>

                    <dt>Sum Assured</dt>
                    <dd>{{ metrics.financial.sum_assured | currency: countryCurrency : 'symbol' :'1.2-2' }}</dd>

                    <dt>Total Insured and Assured</dt>
                    <dd>{{ metrics.financial.sum_total | currency: countryCurrency : 'symbol' :'1.2-2' }}</dd>
                    }

                    <dt>Accumulated tax paid to the government of Kenya</dt>
                    <dd>{{ metrics.financial.accumulated_taxes | currency: 'KES ' : 'symbol' :'1.2-2' }}</dd>

                </dl>
            </div>
        </div>


        <div class="flex flex-col space-y-4">

            <div class="card">

                <h3 class="title">Day</h3>


                <dl>
                    <dt>New insurance contracts</dt>
                    <dd>{{metrics.numerical.purchases.day}}</dd>

                    @if (countryCurrency){
                    <dt>New amounts</dt>
                    <dd>{{ metrics.financial.amounts_day | currency: countryCurrency : 'symbol' :'1.2-2'}}</dd>
                    }

                    <dt>Trainers with over 10 new customers</dt>
                    <dd>{{metrics.numerical.trainerAverages.day}}</dd>

                    <dt>Policies expiring over the next 24 hours</dt>
                    <dd>{{metrics.numerical.expiring.day}}</dd>
                </dl>



            </div>

            <div class="card">

                <h3 class="title">Week</h3>

                <dl>
                    <dt>New insurance contracts</dt>
                    <dd>{{metrics.numerical.purchases.week}}</dd>

                    @if (countryCurrency){
                    <dt>New amounts</dt>
                    <dd>{{ metrics.financial.amounts_week | currency: countryCurrency : 'symbol' : '1.2-2'}}</dd>
                    }

                    <dt>Trainers with over 100 new customers</dt>
                    <dd>{{metrics.numerical.trainerAverages.week}}</dd>

                    <dt>Policies expiring over the next 7 days</dt>
                    <dd>{{metrics.numerical.expiring.week}}</dd>
                </dl>

            </div>

            <div class="card">

                <div class="title">
                    <h3 class="card-title">
                        <span>Month</span>
                    </h3>
                </div>

                <div class="card-body">
                    <dl>
                        <dt>New insurance contracts</dt>
                        <dd>{{metrics.numerical.purchases.month}}</dd>

                        @if (countryCurrency) {
                        <dt>New amounts</dt>
                        <dd>{{ metrics.financial.amounts_month | currency: countryCurrency : 'symbol' : '1.2-2'}}</dd>
                        }


                        <dt>Trainers with over 300 new customers</dt>
                        <dd>{{metrics.numerical.trainerAverages.month}}</dd>

                        <dt>Policies expiring over the next 30 days</dt>
                        <dd>{{metrics.numerical.expiring.month}}</dd>
                    </dl>

                </div>
            </div>

        </div>

    </div>
    }
    @else {
    <div class="flex justify-between items-stretch gap-4 p-4 ">
        <div class="flex-grow">
            <skeleton></skeleton>
        </div>
        <div class="flex-grow">
            <skeleton></skeleton>
        </div>
    </div>
    }
</main>