import { Injectable } from '@angular/core';
import { IQueryResponse, QueryService } from '@core/services/query.service';


export interface CountryModel
{
    id: string;
    name: string;
    currency: string;
}

export interface MetricsNumericalModel 
{
    insuranceContracts: number;
    personsInsured: number;
    membersInsured: number;
    avgHouseholdSize: number;
    claimsSettled: number;
    renewals: {
        renewed: number;
        not_renewed: number;
    };
    expiring: {
        day: number;
        week: number;
        month: number;
    };
    purchases: {
        day: number;
        week: number;
        month: number;
    };
    gender: {
        female: number;
        male: number;
    };
    femaleGenderPercentage: number;
    maleGenderPercentage: number;
    trainers: number;
    trainerAverages: {
        day: number;
        week: number;
        month: number;
    };
    trainerActive: {
        day: number;
        week: number;
        month: number;
    };
};

export interface MetricsFinancialModel
{
    payout: number;
    avgCustomerPremium: number;
    avgHouseholdPremium: number;
    avgCollectivePremium: number;
    avgHouseholdSizeDay: number;
    avgHouseholdSizeWeek: number;
    avgHouseholdSizeMonth: number;
    avgCollectivePremiumDay: number;
    avgCollectivePremiumWeek: number;
    avgCollectivePremiumMonth: number;
    amounts_day: number;
    amounts_week: number;
    amounts_month: number;
    sum_insured: number;
    sum_assured: number;
    sum_total: number;
    accumulated_taxes: number;
}

export interface MetricsStatusModel 
{
    numerical: MetricsNumericalModel;
    financial: MetricsFinancialModel;
    countries: CountryModel[];
}

export interface MetricsStatusQueryResult
{
    metrics: MetricsStatusModel;
}


@Injectable()
export class MetricsService
{
    constructor(private queryService: QueryService) { }

    public getMetrics(countryId?: string): Promise<IQueryResponse<MetricsStatusQueryResult>>
    {
        return this.queryService.queryAsync('MetricsStatusQuery', {countryId});
    }
}
