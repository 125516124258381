import { CommonModule, NgOptimizedImage } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { SkeletonComponent } from '@app/shared/components/skeleton/skeleton.component';
import { SharedModule } from '@app/shared/shared.module';
import { MetricsService, MetricsStatusModel } from '@shared/services/metrics.service';


@Component({
  selector: 'app-metrics',
  standalone: true,
  imports: [CommonModule, SharedModule, FormsModule, SkeletonComponent, NgOptimizedImage],
  templateUrl: './metrics.component.html',
  styleUrl: './metrics.component.css',
})
export class MetricsComponent implements OnInit
{

  public metrics: MetricsStatusModel;
  public countryId?: string = null;
  public countryCurrency?: string = null;

  constructor(private metricsService: MetricsService) { }

  public async ngOnInit(): Promise<void>
  {
    await this.loadAsync();
  }

  public async loadAsync(): Promise<void>
  {
    const response = await this.metricsService.getMetrics(this.countryId);

    if (response && response.isSuccess)
    {
      this.metrics = response.result.metrics;
    }
  }

  public async changeCountryAsync(): Promise<void>
  {
    await this.loadAsync()
    // showing all countries
    if (!this.countryId)
    {
      // hide financials
      this.countryCurrency = null
    }
    else
    {
      // find currency
      const country = this.metrics.countries.find(c => c.id === this.countryId);
      this.countryCurrency = country.currency;
    }
  }
}
