import { NgModule } from '@angular/core';
import { CoreModule } from '@core/core.module';
import { MetricsService } from '@shared/services/metrics.service';


@NgModule({
  declarations: [],
  imports: [
    CoreModule,
  ],
  exports: [],
  providers: [
    MetricsService
  ],
})
export class SharedModule { }
